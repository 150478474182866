/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
*::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
*::-webkit-scrollbar-track {
  background-color: #fff;
}

/* scrollbar itself */
*::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 16px;
  border: 4px solid #fff;

  &:hover {
    background-color: #ababab;
  }
}

/* set button(top and bottom of the scrollbar) */
*::-webkit-scrollbar-button {
  display: none;
}
